<template>
  <div id="user-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <vs-row>
        <vs-col vs-w="3">
          <vs-button
            type="border"
            icon-pack="feather"
            icon="icon-plus-circle"
            @click="onCreateClick"
            >{{ $l.get("dictionary.create-button") }}</vs-button
          >
        </vs-col>
        <vs-col vs-w="9" v-if="isWejoyUserEmailBind">
          <p
            @click="onCancelWejoyUserEmail()"
            style="text-align: right; font-size: 16px; cursor: pointer;"
            class="animated infinite flash"
          >
            {{ data.wejoyUserEmail }}
          </p>
        </vs-col>
      </vs-row>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.usersPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("user-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="name">{{ $l.get("user-list.table-name") }}</vs-th>
          <vs-th sort-key="language">{{
            $l.get("user-form.language-field")
          }}</vs-th>
          <vs-th sort-key="raw_data">{{
            $l.get("dictionary.raw-data", "capitalize")
          }}</vs-th>
          <vs-th>{{ $l.get("user-list.table-actions") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>
              <img
                src="@/application/assets/images/graphics/brazil.png"
                style="width: 32px"
                v-if="tr.language == 'pt-BR'"
              />
              <img
                src="@/application/assets/images/graphics/EUA.png"
                style="width: 32px"
                v-if="tr.language == 'en-US'"
              />
              <img
                src="@/application/assets/images/graphics/spain.png"
                style="width: 32px"
                v-if="tr.language == 'es-ES'"
              />
            </vs-td>
            <vs-td>
              <feather-icon
                icon="ThumbsDownIcon"
                svgClasses="text-danger"
                v-if="tr.rawData == null"
              />
              <feather-icon
                icon="ThumbsUpIcon"
                svgClasses="text-success"
                v-else
              />
            </vs-td>
            <vs-td v-if="isWejoyUserEmailBind">
              <vs-button
                color="success"
                type="border"
                icon="link"
                @click="onUserEditClick(tr)"
              ></vs-button>
            </vs-td>
            <vs-td v-else>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onUserEditClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.usersPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SelectUserEditInteractor from "@/business/user-list/select-user-edit";
import SelectCreateInteractor from "@/business/user-list/select-create";
import InitUserListInteractor from "@/business/user-list/init-user-list";
import ChangeFilterInteractor from "@/business/user-list/change-filter";
import UserListScreenController from "@/adapters/controllers/screen-user-list";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "user-list",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        selectUserEdit: null,
        selectCreate: null,
        initUserList: null,
        changeFilter: null
      },
      isLoading: false,
      page: 1,
      search: "",
      sort: "",
      searchTimer: null,
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(UserListScreenController);

    //{ INTERACTORS
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.initUserList = this.$injector.get(InitUserListInteractor);
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    this.interactors.selectUserEdit = this.$injector.get(
      SelectUserEditInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const wejoyUserEmail = this.$route.query.wue;
    this.interactors.initUserList.handle(wejoyUserEmail);
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  computed: {
    isWejoyUserEmailBind() {
      return !!this.data && !!this.data.wejoyUserEmail;
    }
  },
  methods: {
    setData(data) {
      this.data = data;
      // console.log(data);
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (key) {
        if (active) {
          const prefix = active == "desc" ? "-" : "";
          this.sort = `${prefix}${key}`;
        } else {
          this.sort = "";
        }
        this.interactors.changeFilter.handle(this.page, this.search, this.sort);
      }
    },
    onUserEditClick(data) {
      this.interactors.selectUserEdit.handle(data, this.data.wejoyUserEmail);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle(this.data.wejoyUserEmail);
    },
    onCancelWejoyUserEmail() {
      this.data.wejoyUserEmail = null;
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss"></style>
